<template>
  <div class="pa-4" v-if="this.$store.state.user.role.startsWith('management')">
    <v-container fluid>
      <h2 text color="black" class="headline">デバイス受付センター</h2>
      <v-row dense>
        <v-col
          cols="12" md="12" sm="12" xl="12"
        >
          <v-toolbar
            color="primary"
            flat
            dark
            class="pa-2 top-rounded"
            height="80px"
          >
            <v-select
              hide-details
              eager
              v-model="filterSelectedField"
              label="削除済み"
              :items="filterFields"
              style="max-width: 200px"
              @change="filterItems"
            ></v-select>
            <v-spacer />
            <v-select
              hide-details
              eager
              v-model="searchSelectedField"
              label="検索フィールド"
              :items="searchFields"
              style="max-width: 200px"
              @change="subscribeItems"
            ></v-select>
            <v-spacer />
            <v-text-field
              v-if="searchSelectedField != '権限の種別'"
              class="mt-4"
              full-width
              label="前方一致検索キーワード"
              outlined
              clearable
              v-model="searchKeyword"
              prepend-inner-icon="search"
              @blur="subscribeItems"
              @keyup.enter="subscribeItems"
            >
            </v-text-field>
            <v-radio-group
              v-if="searchSelectedField == '権限の種別'"
              v-model="accessTypeModel"
              row
              @change="subscribeItems"
              >
              <v-radio label="全てのユーザー" value="all"></v-radio>
              <v-radio label="管理ユーザー" value="admin"></v-radio>
              <v-radio label="一般ユーザー" value="normal"></v-radio>
            </v-radio-group>
            <v-spacer />
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page.sync=selectedLinesPerPage
            hide-default-footer
            fixed-header
            height="600"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="viewItemForm(item)" >
                <v-icon class="mx-3">remove_red_eye</v-icon>
              </v-btn>
              <v-btn icon @click="editItemForm(item)" >
                <v-icon class="mx-3">edit</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="deleteItem(item)"
                v-if="!item.deleted"
              >
                <v-icon class="mx-3">delete</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="revertItem(item)"
                v-if="item.deleted"
              >
                <v-icon class="mx-3">restore</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.role="{ item }">
              {{roleNames[item.role]}}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-fab-transition>
          <v-btn
            id="rb-fab"
            color="red"
            large
            dark
            absolute
            right
            fab
            class="rb-fab"
            @click="newItemForm"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
    <reception-dialog
      v-if="mode != 'none'"
      v-model="orgDialogModel"
      :mode="mode"
      :item="currentItem"
      @closed="onDialogClosed"
      @not-deleted="itemNotDeleted"
    />

  </div>
</template>
<script>
import ReceptionDialog from '@/components/ReceptionDialog.vue';
import Orgs from '@/mixins/Orgs.js';

export default {
  name: 'Receptions',
  components: {
    ReceptionDialog
  },
  data() {
    return {
      itemsName: 'staff_orgs',
      type: 'reception',
      typeName: 'デバイス受付センター',
    }
  },
  mixins:[
    Orgs
  ],

}
</script>

<style scoped>
.theme-color {
  color: white;
  background-color:  #B3131A;
}

.top-rounded {
  margin:0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-less {
  border: none !important;
}
</style>
<!-- ユーザーの詳細情報を表示し、編集、削除するためのダイアログ -->
<template>
  <div>
    <v-dialog
      v-if="currentItem"
      v-model="dialogModel"
      min-width="640px"
      :max-width="'90%'"
      @input="closeDialog"
    >
      <v-form>
        <v-card>
          <v-card-title class="headline primary">
            ユーザー {{currentItem.email}} - {{dialogTitle}}
            <v-spacer></v-spacer>
            <v-btn color="white" v-if="currentMode == 'edit'" @click="viewItemForm">
              <v-icon small >details</v-icon> &nbsp;詳細
            </v-btn>
            <v-btn color="white" v-if="currentMode == 'view'" @click="editItemForm">
              <v-icon small >edit</v-icon> &nbsp;編集
            </v-btn>
            <v-btn
              color="error"
              class="ml-4"
              v-if="currentMode != 'new' && isAdminRole && !isDeletedItem"
              @click="deleteItem(currentItem)"
            >
              <v-icon small >delete</v-icon> &nbsp;削除
            </v-btn>
            <v-btn
              color="info"
              class="ml-4"
              v-if="isDeletedItem"
              @click="revertItem(currentItem)"
            >
              <v-icon small >restore</v-icon> &nbsp;復元
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row dense align="center">
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="currentItem.email"
                      label="Emailアドレス（必須）"
                      :rules="emailRules"
                      :readonly="currentMode != 'new'"
                      ref="email"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="currentItem.name"
                      label="氏名（必須）"
                      :rules="nameRules"
                      :readonly="currentMode == 'view'"
                      ref="name"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="currentItem.name_kana"
                      label="ふりがな（必須）"
                      :readonly="currentMode == 'view'"
                      :rules="nameKanaRules"
                      ref="nameKana"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="currentItem.phone_number"
                      label="電話番号"
                      :readonly="currentMode == 'view'"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <label>権限の種別</label>
                    <v-radio-group
                      v-model="accessType"
                      row
                      :readonly="currentMode == 'view' || !isAdminRole">
                      <v-radio label="管理ユーザー" value="admin" ></v-radio>
                      <v-radio label="一般ユーザー" value="normal" ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-sheet>
                      <v-switch
                        v-model="currentItem.is_email_off"
                        label="通知メールを受信しない"
                        :readonly="currentMode == 'view'"
                      ></v-switch>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="currentItem.remarks"
                      label="備考">
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row dense align="center">

                  <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                    <v-text-field
                      v-model="currentItem.created"
                      label="登録日時"
                      :readonly="true"
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                    <v-text-field
                      v-model="currentItem.modified"
                      label="最終更新日時"
                      :readonly="true"
                    />
                  </v-col>
                </v-row>

                <v-row dense v-if="currentMode == 'view'">
                  <v-col>
                    <v-row dense>
                      <v-col>
                        利用規約同意の記録
                      </v-col>
                    </v-row>
                    <v-row dense v-for="(item, key) in currentItem.terms_of_services" :key="key">
                      <v-col cols="12" md="4" >
                        <v-text-field
                          hide-details
                          dense
                          :value="item.label"
                          :readonly="true"
                        />
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          hide-details
                          dense
                          :value="item.version"
                          :readonly="true"
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          hide-details
                          dense
                          :value="item.datetime"
                          :readonly="true"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

            </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="primary darken-1" text @click="closeDialog">閉じる</v-btn>
            <v-btn v-if="currentMode == 'edit'" color="warning darken-1" text @click="onClickActionBtn('saveItem', currentItem)" :disabled="isActionBtnDisabled">保存</v-btn>
            <v-btn v-if="currentMode == 'new'" color="warning darken-1" text @click="onClickActionBtn('newItem', currentItem)" :disabled="isActionBtnDisabled">作成</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>

import StaffDialog from '@/mixins/StaffDialog.js';

export default {
  name: 'ReceptionStaffDialog',
  components: {

  },
  mixins: [
    StaffDialog
  ],
  data() {
    return {
      itemsName: 'staffs',
      type: 'reception',
      typeName: 'デバイス受付センター',
    }
  },
  computed: {
    isAdminRole() {
      if (this.$store.state.user.role.endsWith('-admin')
        || this.$store.state.user.role == 'management'
      ) {
        return true;
      }
      return false;
    }
  },
}
</script>

<style scoped>
  .primary {
    color: white;
  }
</style>
<!-- 販売事業者組織の詳細情報を表示し、編集、削除するためのダイアログ -->
<template>
  <div>
    <v-dialog
      v-if="currentItem"
      v-model="dialogModel"
      min-width="640px"
      :max-width="'90%'"
      @input="closeDialog"
    >
      <v-form>
        <v-card>
          <v-card-title class="headline primary">
            [{{typeName}}] {{currentItem.name}} - {{dialogTitle}}
            <v-spacer></v-spacer>
            <v-btn color="white" v-if="currentMode == 'edit'" @click="viewItemForm">
              <v-icon small >details</v-icon> &nbsp;詳細
            </v-btn>
            <v-btn color="white" v-if="currentMode == 'view'" @click="editItemForm">
              <v-icon small >edit</v-icon> &nbsp;編集
            </v-btn>
            <v-btn
              color="error"
              class="ml-4"
              v-if="currentMode != 'new' && !isDeletedItem"
              @click="deleteItem(currentItem)"
            >
              <v-icon small >delete</v-icon> &nbsp;削除
            </v-btn>
            <v-btn
              color="info"
              class="ml-4"
              v-if="isDeletedItem"
              @click="revertItem(currentItem)"
            >
              <v-icon small >restore</v-icon> &nbsp;復元
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row dense align="center">
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="currentItem.name"
                      label="デバイス受付センター名（必須）"
                      :rules="nameRules"
                      :readonly="currentMode == 'view'"
                      ref="name"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="currentItem.postal_code"
                      label="郵便番号（必須）"
                      :rules="postalCodeRules"
                      :readonly="currentMode == 'view'"
                      ref="postal_code"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="currentItem.address"
                      label="住所（必須）"
                      :rules="addressRules"
                      :readonly="currentMode == 'view'"
                      ref="address"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="currentItem.phone_number"
                      label="電話番号（必須）"
                      :rules="phoneRules"
                      :readonly="currentMode == 'view'"
                      ref="phone_number"
                    />
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="currentItem.email"
                      label="連絡先Emailアドレス（必須）"
                      :readonly="currentMode == 'view'"
                      :rules="emailRules"
                      ref="email"
                    />
                  </v-col>
                  <v-col cols="12" md="12" >
                    <v-textarea
                      v-model="currentItem.remarks"
                      :readonly="currentMode == 'view'"
                      label="備考">
                    </v-textarea>
                  </v-col>
                  <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                    <v-text-field
                      v-model="currentItem.created"
                      label="登録日時"
                      :readonly="true"
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                    <v-text-field
                      v-model="currentItem.modified"
                      label="最終更新日時"
                      :readonly="true"
                    />
                  </v-col>
                  <v-col cols="12" md="3" v-if="currentMode == 'view'" >
                    <v-text-field
                      v-model="currentItem.id"
                      label="ID"
                      :readonly="true"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="currentMode == 'view'">
                  <v-col cols="12">
                    <reception-staffs :item="currentItem" />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="primary darken-1" text @click="closeDialog">閉じる</v-btn>
            <v-btn v-if="currentMode == 'edit'" color="warning darken-1" text @click="onClickActionBtn('saveItem',currentItem)" :disabled="isActionBtnDisabled">保存</v-btn>
            <v-btn v-if="currentMode == 'new'" color="warning darken-1" text @click="onClickActionBtn('newItem', currentItem)" :disabled="isActionBtnDisabled">作成</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import ReceptionStaffs from '@/components/ReceptionStaffs.vue';
import OrgDialog from '@/mixins/OrgDialog.js';

// let itemsName = 'salses_orgs';
// let itemsRef = null;

export default {
  name: 'ReceptionDialog',
  components: {
    ReceptionStaffs
  },
  mixins: [
    OrgDialog
  ],
  data() {
    return {
      itemsName: 'staff_orgs',
      type: 'reception',
      typeName: 'デバイス受付センター',
    }
  },
}
</script>

<style scoped>
  .primary {
    color: white;
  }
</style>